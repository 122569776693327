<template>
    <div>
        <section class="text-center">
            <b-container>
                <h1 class="font-weight-light">Contact</h1>
                <hr/>
                <p>
                    Rejoignez-nous sur Facebook et Discord, ou contactez-nous par mail :
                </p>
                <p>
                    <a href="https://www.instagram.com/cluji_insa/"
                       target="_blank"
                       class="social-link mr-5"
                       v-b-tooltip.hover="'Instagram'"
                       :style="{color: '#FCAF45'}">
                        <font-awesome-icon :icon="['fab', 'instagram-square']"/>
                    </a>
                    <a href="https://www.facebook.com/Cluji/"
                       target="_blank"
                       class="social-link mr-5"
                       v-b-tooltip.hover="'Facebook'"
                       :style="{color: '#1773ea'}">
                        <font-awesome-icon :icon="['fab', 'facebook-square']"/>
                    </a>
                    <a href="https://discordapp.com/invite/VyFuRpD"
                       target="_blank"
                       class="social-link"
                       v-b-tooltip.hover="'Discord'"
                       :style="{color: '#7289da'}">
                        <font-awesome-icon :icon="['fab', 'discord']"/>
                    </a>
                    <a :href="'mailto:'+email"
                       class="social-link ml-5"
                       v-b-tooltip.hover="'Mail'"
                       :style="{color: '#29b700'}">
                        <font-awesome-icon icon="envelope-square"/>
                    </a>
                </p>
            </b-container>
        </section>

        <section class="mt-5">
            <b-container class="text-center">
                <h1 class="font-weight-light">Adresse</h1>
                <hr/>
                <p>
                    Rez-de-Chaussée Résidence C, Campus de la Doua<br/>
                    20 Avenue Albert Einstein, 69100 Villeurbanne
                </p>
            </b-container>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.553405463983!2d4.870666315848916!3d45.780139720326524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ea9f9f4c6c97%3A0x6bfbfe6babf94d79!2sCluji!5e0!3m2!1sfr!2sfr!4v1560727161913!5m2!1sfr!2sfr"
                    height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </section>

        <section class="mt-5 text-center">
            <b-container>
                <h1 class="font-weight-light">Horaires d'ouverture du local</h1>
                <hr/>
                <p>
                    Jeu d'échec et de stratégie les lundis à partir de 20h.<br/>
                    Jeux de société et de cartes les mercredis et vendredis à partir de 20h.
                </p>
                <p>
                    Pour réserver le local en dehors de ces horaires, merci de contacter l'adresse
                    <a :href="'mailto:'+email">{{ email }}</a>.
                </p>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: "About",
        data: () => ({email: process.env.VUE_APP_CLUJI_EMAIL})
    }
</script>

<style scoped>
    iframe {
        width: 100% !important;
    }

    a.social-link {
        font-size: 64px;
    }

    a.social-link:hover {
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, .5));
    }
</style>
